<template>
	<footer class="footer-cont pt-12 color-white fw-300 lh-14">
		<section id="contact-form" class="set-appointment color-white back-beige">
			<div class="container">
				<appointment></appointment>
			</div>
		</section>
		<div class="container margin012  mt-12 mb-5">
			<div class="menu-social-address-lang flex f-space-between f-gap-8">
				<nav class="footer-menu">
					<ul role="list">
						<li>
							<a href="" v-scroll-to="{el: '#how-we-work'}"
							   class="uppercase navbar-menu-item color-white">
								<span class="hovergrad">How we work</span>
							</a>
						</li>
						<li>
							<a href="" v-scroll-to="{el: '#our-services'}"
							   class="uppercase navbar-menu-item color-white">
								<span class="hovergrad">Our Services</span>
							</a>
						</li>
						<li>
							<a href="" v-scroll-to="{el: '#testimonials'}"
							   class="uppercase navbar-menu-item color-white">
								<span class="hovergrad">Testimonials</span>
							</a>
						</li>
						<li></li>
						<li>
							<a href="https://dotswan.com/#/general-terms-and-conditions">Legal Terms</a>
						</li>
					</ul>
				</nav>
				<ul class="socials" role="list" aria-label="social links">
					<li><a href="" aria-label="instagram">Instagram</a></li>
					<li><a href="" aria-label="linkedin">Linkedin</a></li>
					<li><a href="" aria-label="twitter">Twitter</a></li>
				</ul>
				<div class="address">
					<p>Anthonetta Kuijlstraat 48, 3066GS Rotterdam, Netherlands</p>
					<a href="">+31-108082684</a>
				</div>
				<div class="lang">
					<select>
						<option value="en" selected>English</option>
						<option value="nl">Dutch</option>
					</select>
				</div>
			</div>
		</div>
		<div class=" margin012 fs-12 lh-14 fw-300  pb-7 small-dir-col">
			<div class="container flex f-space-between copyright pt-3">
				<span class="copy uppercase color-gray_light">© 2023 .dotSkill</span>
				<span class="powered uppercase">Powered by <a href="">.dotSwan</a> with love, a lot of it.</span>
			</div>
		</div>
	</footer>
</template>
<script>
import Appointment from "@/js/components/appointment";

export default {
	components: {Appointment},
	name: 'Foooter',
	computed: {},
	props: {},
	data() {
		return {}
	},
	methods: {
		setUp() {

		},
	},
	mounted() {
	},
	watch: {}
}
</script>