<template>
	<div class="comments-slider" :style="loadedStyle">
		<div class="arrow-cont left" @click="slide('left')">
			<img :src="$store.getters.getPaths.media +'img/icons/arrow-left.svg'">
		</div>
		<div class="comments" ref="inner">
			<div class="comments-wrapper" :style="innerStyles" ref="wrapper">
				<div v-for="(item,index) in slides" class="comment" :class="[{'active':index===activeFixedIndex}]"
				     :key="item.id" @click="slideByIndex(index)">
					<q :class="qClasses(index)">{{ item.comment }}</q>
					<div class="user-info">
						<div class="avatar-cont">
							<img :src="$store.getters.getPaths.media + item.avatar">
						</div>
						<div class="user-company">
							<b>{{ item.name }}</b>
							<span>{{ item.company }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="arrow-cont right" @click="slide('right')">
			<img :src=" $store.getters.getPaths.media + 'img/icons/arrow-right.svg'">
		</div>
	</div>
</template>
<script>
export default {
	name: 'CommentsSlider',
	computed: {
		loadedStyle() {
			return this.up ? {
				visibility: 'visible'
			} : {
				visibility: 'hidden'
			};
		}
	},
	props: {},
	data() {
		return {
			activeFixedIndex: 2,
			up: false,
			innerStyles: {},
			translate: '',
			transitioning: false,
			slides: [
				{
					id: 0,
					active: true,
					avatar: "media/comments/Rik.jpg",
					name: "Rik Meijer",
					company: "Hestiva",
					comment: "I have a very good experience with Dotswan. Thanks to the dedicated team that is always there for you, you are very well supported in all processes. They have helped me a lot in developing all my software. Nothing is too crazy for them! Many thanks for your help. I will recommend it to everyone!"
				},
				{
					id: 1,
					active: false,
					avatar: "media/comments/Jullaya.jpeg",
					name: "Jullaya Vorasuntharosoth",
					company: "SOMI",
					comment: "I had a very pleasant collaboration with Dotswan. They delivered high quality work in a given time frame and responded to every feedback. Definitely recommended."
				},
				{
					id: 2,
					active: false,
					avatar: "media/comments/Joyce.png",
					name: "Joyce Pont",
					company: "VondellaanVastgoed",
					comment: "Dotswan has a wide range of knowlegde and provides our company with the support we need to keep us productive, up to date and online. It is great to work with their professional team!"
				},
				{
					id: 3,
					active: false,
					avatar: "media/comments/Seth.jpeg",
					name: "Seth James Haringa",
					company: "LeadMind Agency",
					comment: "We have had the pleasure of working with Dostwan for two years now, and they have become our preferred supplier for web and app design as well as other IT tasks. Their skilled team of professionals consistently delivers high-quality work, and it has been a pleasure working with them on a personal level as well. They take the time to listen to our needs and are always easy to communicate with."
				},
			]
		}
	},
	methods: {
		qClasses(index) {
			if (index === this.activeFixedIndex) {
				return "animate__animated animate__fadeInUp"
			} else {
				return "animate__fadeOutDown"
			}
		},
		swipeSupport() {
			let container = this.$refs.inner,
					initialX = null,
					initialY = null,
					_this = this;
			container.addEventListener("touchstart", startTouch, false);
			container.addEventListener("touchmove", moveTouch, false);

			function startTouch(e) {
				initialX = e.touches[0].clientX;
				initialY = e.touches[0].clientY;
			}

			function moveTouch(e) {
				if (initialX === null) {
					return;
				}

				if (initialY === null) {
					return;
				}

				var currentX = e.touches[0].clientX;
				var currentY = e.touches[0].clientY;

				var diffX = initialX - currentX;
				var diffY = initialY - currentY;

				if (Math.abs(diffX) > Math.abs(diffY)) {
					// sliding horizontally
					if (diffX > 0) {
						// swiped left
						_this.slide('left');
						// console.log("swiped left");
					} else {
						// swiped right
						_this.slide('right');
						// console.log("swiped right");
					}
				} else {
					// sliding vertically
					if (diffY > 0) {
						// swiped up
						// console.log("swiped up");
					} else {
						// swiped down
						// console.log("swiped down");
					}
				}

				initialX = null;
				initialY = null;

				e.preventDefault();
			};
		},
		setUp() {
			this.calcTranslate();
			this.resetTranslate();
			this.swipeSupport();
			this.watchResize();
			setTimeout(this.resized, 1000)
		},
		resized() {
			this.up = true
			this.calcTranslate();
			this.resetTranslate();
		},
		watchResize() {
			window.addEventListener("resize", this.resized);
		},
		calcTranslate() {
			let
					commentWidth = this.$refs.wrapper.querySelector('.comment').scrollWidth,
					// innerWidth = this.$refs.inner.scrollWidth,
					totalslides = this.slides.length;
			if (this.$store.getters.getDevice.width <= 720) {
				this.translate = 0;
			} else {
				// this.translate = `${innerWidth / totalslides}px`;
				this.translate = `${commentWidth}px`;
			}
		},
		slideByIndex(index) {
			if (index > this.activeFixedIndex) {
				this.slide('right')
			} else {
				this.slide('left')
			}
		},
		slide(direction) {
			if (direction === 'right') {
				if (this.transitioning)
					return
				this.transitioning = true
				this.moveLeft()
				this.afterTransition(() => {
					const card = this.slides.shift()
					this.slides.push(card)
					this.resetTranslate()
					this.transitioning = false
				})
			} else if (direction === 'left') {
				if (this.transitioning)
					return
				this.transitioning = true
				this.moveRight()
				this.afterTransition(() => {
					const card = this.slides.pop()
					this.slides.unshift(card)
					this.resetTranslate()
					this.transitioning = false
				})
			}
		},
		moveLeft() {
			this.innerStyles = {
				transform: `translateX(-${this.translate})
                    translateX(-${this.translate})`
			}
		},
		moveRight() {
			this.innerStyles = {
				transform: `translateX(${this.translate})
                    translateX(-${this.translate})`
			}
		},
		afterTransition(callback) {
			const listener = () => {
				callback()
				this.$refs.inner.removeEventListener('transitionend', listener)
			}
			this.$refs.inner.addEventListener('transitionend', listener)
		},
		resetTranslate() {
			this.innerStyles = {
				transition: 'none',
				transform: `translateX(-${this.translate})`
			}
		}
	},
	mounted() {
		this.setUp();
	}
}
</script>