<template>
	<header class="nav-header-cont padding08 padding80"
	        :class="[{'open-ham': $store.getters.getNav}]">
		<div class="container flex f-gap-8 f-align-center f-space-between">
			<slot></slot>
			<hamburger></hamburger>
		</div>
	</header>
</template>
<script>
import Hamburger from "@/js/components/Hamburger";

export default {
	name: 'Navbar',
	components: {Hamburger},
	data: () => {
		return {
			// menuItems: [
			// 	{name: "How we work", alias: 'how-we-work', link: '/how-we-work', id: 0, target: '#how-we-work'},
			// 	{name: "Our Services", alias: 'our-services', link: '/our-services', id: 1, target: '#our-services'},
			// 	{name: "Testimonials", alias: 'testimonials', link: '/Testimonials', id: 2, target: '#testimonials'},
			// 	{name: "FAQ", link: '/faq', alias: 'faq', id: 3},
			// ]
		}
	}
}
</script>