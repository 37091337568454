<template>
	<div class="filler">
		<div class="wrapper">
			<div class="inner-wrapper">
				<div class="circle-wrapper">
					<div class="items-container" ref="itemsContainer" :style="style">
						<div class="item" v-for="(item , index) in carItems"
						     :style="'transform: rotate('+ (index * 40) + 'deg)'">
							<div class="item-inner" :style="'transform: rotate('+ (angle * -1) + 'deg)'">
								<b>{{ item.number }}</b>
								<span>{{ item.title }}</span>
								<p>{{ item.desc }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="controls selection-off">
		<div class="left selection-off" @click="slideLeft">
			<i class="fas fa-caret-left">
				left
			</i>
		</div>
		<div class="right selection-off" @click="slideRight">
			<i class="fas fa-caret-right">
				right
			</i>
		</div>
	</div>
</template>
<script>
export default {
	name: 'carousel',
	computed: {
		style() {
			return {'transform': "rotate(" + this.angle + "deg)"}
		},
		carItems() {
			let res = [];
			res = this.slideItems
			return res;
		}
	},
	props: {
		lang: '',
		translation: '',
	},
	data() {
		return {
			angle: 0,
			slideItems: [
				{
					number: "01",
					title: "Talent Sourcing",
					desc: "We use a variety of methods to identify and source top talent for your company, including online job boards, social media recruiting, and targeted outreach to passive candidates."
				},
				{
					number: "02",
					title: "Candidate Screening",
					desc: "We carefully review resumes and applications to ensure that only the most qualified candidates are considered for open positions. We also conduct initial screenings to assess candidates' skills, experience, and fit for your company culture."
				},
				{
					number: "03",
					title: "Interviewing and Selection",
					desc: "We work with you to develop interview questions and evaluation criteria, and we conduct thorough interviews to assess candidates' fit for the position and your company culture. We also assist with final candidate selection and offer negotiations."
				}
			]
		}
	},
	methods: {
		setUp() {
			var htmlCollection = document.querySelectorAll(".item");
			var el = this.$refs.itemsContainer
			var st = window.getComputedStyle(el, null);
			var tr = st.getPropertyValue("transform") || "FAIL";

			var values = tr.split('(')[1].split(')')[0].split(',');
			var a = values[0];
			var b = values[1];
			var c = values[2];
			var d = values[3];
			this.angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
		},
		slideRight() {
			this.angle -= 10;
			console.log(this.angle)
		},
		slideLeft() {
			this.angle += 10;
			console.log(this.angle)
		}
	},
	mounted() {
		this.setUp();
	},
	watch: {}
}
</script>