<template>
	<div class="link-select" v-if="!!items && !!items.length" :class="[{'open': open}]">
		<div class="place-holder" @click="openMenu">
			<div class="img=-title flex f-align-center">
				<img :src="$store.getters.getPaths.media + 'img/icons/'+placeHolder.icon" class="flag">
				<span class="title">{{ placeHolder.title }}</span>
			</div>
			<img :src="$store.getters.getPaths.media + 'img/icons/arrow-down.svg'" class="arrow">
		</div>
		<div class="menu back-gray_dark">
			<ul>
				<li v-for="item in items" @click="closeMenu">
					<a :href="'/' + item.alias" :data-value="item.title">
						<img :src="$store.getters.getPaths.media + 'img/icons/'+item.icon" class="flag">
						<span>{{ item.title }}</span>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	computed: {
		placeHolder() {
			return this.items.filter(item => item.active)[0]
		}
	},
	props: {
		current: '',
		langs: Array
	},
	name: 'LinkSelect',
	data() {
		return {
			items: [],
			open: false,
			ignore: false
		}
	},
	methods: {
		openMenu() {
			this.open = true;
			this.ignore = true;
			this.setClickListenerOutside();
		},
		closeMenu() {
			this.open = false;
			this.unsetClickListenerOutside();
		},
		outsideClick() {
			console.log(1)
			if (this.ignore) {
				this.ignore = false;
			} else {
				if (this.open) {
					this.closeMenu();
				}
			}
		},
		setClickListenerOutside() {
			document.addEventListener('click', this.outsideClick)
		},
		unsetClickListenerOutside() {
			removeEventListener('click', this.outsideClick);
		},
		setUp() {
			this.items = [];
			this.langs.map(item => {
				let tmp = item;
				tmp.active = item.alias === this.current;
				this.items.push(tmp)
			})
		},
	},
	mounted() {
		this.setUp()
	},
}
</script>