<template>
	<div class="appointment">
		<div class="appointment-cont" ref="appointment">
			<div class="title-form">
				<h2 class="capital lh-100 fs-135 animatedTitle mb-8 color-gray_dark">Join a delicious meeting!</h2>
				<p class="fs-20 lh-14 color-gray_dark mb-4">let us know by filling the form, so we will contact you to arrange a meeting & drink a
				                coffee.</p>
				<form class="form" @submit.stop.prevent="submitForm" ref="form">
					<input type="hidden" name="fieldF14F153" value="request for meeting">
					<div class="fields">
						<input name="fieldF14F151" placeholder="Your Name" required class="uppercase lh-14">
						<input name="fieldF14F152" type="email" placeholder="Your email" class="uppercase lh-14">
					</div>
					<div class="submit al-right mt-4">
						<span class="sending color-gray_dark mr-1 animate__animated trans-3"
						      :class="[{'animate__fadeInRight': loading}, {'animate__fadeOutRight' : !loading}]">
							<span class="mr-1">Sending</span>
							<loading></loading>
						</span>
						<div :class="[{'animate__fadeInRight': !loading && sent && success}, {'animate__fadeOutRight': loading || !sent || !success}]"
						     class="animate__animated trans-3 btn black outline ver-al-mid">
							<span class="ver-al-mid capital">congrats! You Are in, We will call you Soon!</span>
						</div>
						<button :class="[{'wait-loading' : loading}, {'success': success}, {'animate__fadeOutRight': !loading && sent && success}]"
						        :disabled="loading"
						        class="trans-3 btn black uppercase animate__animated"
						        v-btn>
							Lets go!
						</button>
					</div>
				</form>
			</div>
			<div class="office">
				<div class="list-cont">
					<ul role="list" :style="innerStyles">
						<li><img :src="$store.getters.getPaths.media + 'media/office/coffee.jpg'" alt=""></li>
						<li><img :src="$store.getters.getPaths.media + 'media/office/meeting.jpg'" alt=""></li>
						<li><img :src="$store.getters.getPaths.media + 'media/office/office(1).jpg'" alt=""></li>
					</ul>
					<ul role="list" class="rev" :style="innerStylesRev">
						<li><img :src="$store.getters.getPaths.media + 'media/office/office(2).jpg'" alt=""></li>
						<li><img :src="$store.getters.getPaths.media + 'media/office/office(3).jpg'" alt=""></li>
						<li><img :src="$store.getters.getPaths.media + 'media/office/office(5).jpg'" alt=""></li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loading from "@/js/components/loading";

export default {
	name: "Appointment",
	components: {Loading},
	computed: {
		innerStyles() {
			return {
				transform: 'translateY(' + this.count * -2 + 'px)'
			}
		},
		innerStylesRev() {
			return {
				transform: 'translateY(' + this.count * 2 + 'px)'
			}
		}
	},
	data: () => {
		return {
			loading: false,
			success: false,
			sent: false,
			move: false,
			count: 0,
			scrollPos: 0,
			watchElm: null
		}
	},
	methods: {
		setUp() {
			this.watchElm = this.$refs.appointment.parentElement;
			this.setObserver();
			document.addEventListener('scroll', this.scrollChange, {passive: true});
		},
		submitForm() {
			let data = new FormData(this.$refs.form);
			this.loading = true;
			Axios({
				url: 'https://bewaarder.nl/api/forms/submit/dotskill_contact',
				method: 'POST',
				data: data
			}).then((res) => {
				this.loading = false;
				this.success = true;
				this.sent = true;
			}).catch((err) => {
				this.loading = false;
				this.sent = true;
			})
		},
		scrollChange() {
			if ((document.body.getBoundingClientRect()).top > this.scrollPos) {
				if (this.move)
					this.count++;
			} else {
				if (this.move)
					this.count--;
			}
			this.scrollPos = (document.body.getBoundingClientRect()).top;
		},
		setObserver() {
			let _this = this;

			function doIt() {
				_this.move = true;
			}

			function undoIt() {
				_this.count = 0
				_this.move = false;
			}

			function handleIntersect(entries, observer) {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						// observer.unobserve(_this.$refs.badgeCont);
						doIt();
					} else {
						undoIt()
					}
				});
			}

			function createObserver() {
				const options = {
					root: null,
					threshold: 0
				};
				const observer = new IntersectionObserver(handleIntersect, options);
				observer.observe(_this.watchElm);
			}

			if (window["IntersectionObserver"]) {
				createObserver();
			}
		}
	},
	mounted() {
		this.setUp();
	}
}
</script>